<template>
  <div class="no-padding">
    <b-select v-model="computedValue" :placeholder="fieldTitle">
      <option v-if="computedValue" class="nofilter" value>{{ fieldTitle }}</option>
      <option value="Belum">Belum</option>
      <option value="Ya">Terverifikasi</option>
      <option value="Tidak">Ditolak</option>
    </b-select>
  </div>
</template>


<script>
export default {
  name: "VerifikasiSelect",
  props: { value: String },
  data() {
    return {
      fieldTitle: "Verifikasi",
      valueData: this.value
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.valueData;
      },
      set(value) {
        this.valueData = value;
        this.$emit("input", value);
      }
    }
  },
  watch: {
    // When v-model is changed: update internal value
    value(value) {
      this.valueData = value;
    }
  }
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 480px) {
  .filter2 {
    flex: none;
    width: 5rem;
  }

  .filter3 {
    flex: none;
    width: 4rem;
  }
}

::v-deep option.nofilter {
  color: #999;
}
</style>
